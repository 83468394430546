#container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    background-color: #1e073c;
    height: 10vh;
    align-items: center;
}

h1 {
    margin: 0;
    color: #ffffff;
    text-align: center;
}

#loginButton {
    margin-left: auto;
}

#logoutButton {
    margin: auto;
    padding: 2px;
}

#NavbarLeft {
    grid-area: 1 / 1 / 2 / 2;
}

#NavbarCenter {
    grid-area: 1 / 2 / 2 / 3;
}

#NavbarRight {
    grid-area: 1 / 3 / 2 / 4;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-direction: column;
}

#loggedIn {
    color: #ffffff;
    margin-top: 3px;
    margin-bottom: 10px;
}

/* Styles for screens between 375px and 667px */
@media (min-width: 375px) and (max-width: 667px) {
    #logginIn {
        width: 50%;
    }
}