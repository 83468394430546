.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.mh {
  display: flex;
}

.dh {
  display: none;
}

#playlist-container {
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  right: 0;
  width: 40%; /* or any width you prefer */
  height: 100vh;
  background-color: rgba(11, 3, 20,0.95);
  box-shadow: -2px 0 5px rgba(0,0,0,0.3);
  overflow-y: auto;
  z-index: 1000; /* Make sure it appears on top of other elements */
  transition: transform 0.3s ease; /* Smooth transition */
  transform: translateX(100%); /* Initially hidden */
}

#playlist-container.visible {
  transform: translateX(0); /* Slide in when visible */
}

#togglePlaylistButtonContainer {
  position: fixed;
  display: flex;
  flex-direction: column;
  bottom: 10px;
  right: 10px;
  padding: 10px 0;
  z-index: 1001; 
}

#togglePlaylistButton {
  background-color: #1e073c;
  color: white;
  border: none;
  border-radius: 5px;
  text-wrap: pretty;
  width: 4.5rem;
}

#playlistSongCounter {
  all: unset;
  position: absolute;
  top: 15px;
  right: -5px;
  display: flex;
  background-color: #542379;
  border-radius: 100%;
  width: 24px;
  height: 24px;
  align-content: center;
  justify-content: center;
  color: #ffffff;
}

#playlistName {
  margin: 20px auto;
  margin-top: 30px;
  padding: 5px;
  width: 75%;
}

/* Styles for screens between 375px and 667px */
@media (max-width: 667px) {
  p {
    font-size: 0.75rem; /* Adjust font size for mobile */
  }

  .mh {
    display: none; /* Hide mobile-hidden elements */
  }

  .dh {
    display: block; /* Show desktop-hidden elements */
  }

  #playlist-container {
    width: 100%;
    height: 100%;
  }

  #SearchBarContainer {
    display: flex;
    flex-direction: column;
    height: auto;
    width: 90%;
  }

  #searchTypeSelector {
    height: 3rem;
    border: none;
  }

  input {
    text-align: center;
    padding-left: 0;
    height: 3rem;
  }

  #loginButton {
    width: 5rem;
    margin: auto;
  }

  #logoutButton {
    margin: auto;
    width: auto;
    padding: 0.5rem 2rem;
  }

}