p {
    font-size: 1rem;
}

#trackContainer {
    border-radius: 50px;
    display: flex;
    background: linear-gradient(90deg, #0b0314 40%, #270541 100%);
    color: white;
    padding-left: 5px;
    align-items: center;
    margin: 0 auto;
    margin-top: 5px;
    width: 75%;
    height: 3.5rem;
}

#artworkImg { 
    display: flex;
    height: 2.75rem;
    width: auto;
    align-items: center;
}

#previewAudio {
    display: flex;
    width: 2rem;
    height: auto;
    align-items: center;
}

#addToPlaylist {
    display: flex;
    width: 2rem;
    height: auto;
    align-items: center;
    margin-left: auto;
    padding-right: 2%;
}

#removeFromPlaylist {
    display: flex;
    width: 2rem;
    height: auto;
    align-items: center;
    margin-left: auto;
    padding-right: 2%;
}

#prevAudioContainer {
    padding: 0 5px;
}

#artworkImgContainer {
    padding: 0 5px;
}

#track-inf {
    display: flex;
    flex-direction: column;
}

#artist, #name, #album {
    margin: 0;
}

#album {
    color: rgba(245, 245, 245, 0.548);
    font-size: 0.75rem;
}

#noPreviewAudio {
    opacity: 0.5;
    display: flex;
    width: 2rem;
    height: auto;
    align-items: center;
}

#playlist-item-info {
    display: flex;
    flex-direction: column;
    padding-left: 1rem;
    justify-items: center;
}