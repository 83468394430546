.container {
    display:flex;
    background-color:rgb(51, 1, 89);
    background-image: url(../public/Headphones.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    background-blend-mode: screen;
    min-Height:90vh;
    height:90vh;
    justify-Content:center;
    align-Items:center;
}

form {
    display:flex;
    flex-Direction:column;
    justify-Content:center;
}

#search {
    color: white;
    border: none;
    background-color: transparent
}

input::placeholder {
    color: rgba(224, 216, 231, 0.575);
    font-weight: bold;
}

input {
    padding-left: 1rem;
}

button {
    padding: 0.5rem;
    width: 25%;
    margin: 1rem auto;
    border-radius: 50px;
    background-color: rebeccapurple;
    border: 3px violet solid;
    color: white;
}

button:hover {
    background-color: blueviolet;
    border: 3px pink solid;
}

#SearchBarContainer {
    display: grid;
    grid-template-columns: 2fr 0.75fr;
    min-width: 25rem;
    max-width: 60rem;
    height: 3rem;
    min-height: 2rem;
    background-color: rgba(51, 1, 89, 0.502);    
    border: 3px white solid;
    border-radius: 10px;    
    box-shadow: 0px 0px 25px rgb(51, 1, 89);
}

#searchTypeSelector {
    background-color: rebeccapurple;
    border-color: transparent;
    border-left: 2px white solid;
    border-bottom-right-radius: 10px;
    border-top-right-radius: 10px;
    outline: 0px;
    color: white;
    text-align: center;
}